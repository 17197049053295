import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios, { AxiosResponse } from "axios";
import {
  Box,
  Button,
  Typography,
  Container,
  useMediaQuery,
  createTheme,
} from "@mui/material";

import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SearchIcon from "@mui/icons-material/Search";

import RoiSelection from "./RoiSelection";

const theme = createTheme();

const Home: React.FC = () => {
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // const [projects, setProjects] = useState<Project[]>([]);
  // const [projectName, setProjectName] = useState<string>("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [roi, setRoi] = useState<[number, number, number, number] | null>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      var image_url = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(image_url);
      setImageFile(event.target.files[0]);
    }
  };

  // const handleProjectChange = (event: SelectChangeEvent<string>) => {
  //   setProjectName(event.target.value as string);
  // };

  // useEffect(() => {
  //   axios
  //     .get<{ data: Project[] }>("/sol_server/project/all_projects")
  //     .then((response: AxiosResponse<{ data: Project[] }>) => {
  //       setProjects(response.data.data);
  //       setProjectName("Test_Nesspresso");
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //     });
  // }, []);

  const cropImage = () => {
    const image = new Image();
    image.src = selectedImage;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      if (ctx && roi) {
        // Set canvas dimensions to match the crop dimensions
        canvas.width = roi[2];
        canvas.height = roi[3];

        ctx.drawImage(
          image,
          roi[0],
          roi[1],
          roi[2],
          roi[3], // Source
          0,
          0,
          roi[2],
          roi[3] // Destination
        );

        // Convert the canvas to a blob
        canvas.toBlob((blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            setCroppedImage(url);
          }
        }, "image/jpeg"); // You can choose the format (e.g., 'image/png')
      }
    };
  };

  useEffect(() => {
    if (croppedImage) {
      navigate("/results", {
        state: { croppedImage },
      });
    }
  }, [croppedImage]);

  const handleDetect = () => {
    if (roi) {
      cropImage();
    } else {
      setCroppedImage(selectedImage);
    }
  };

  const handleRoiSelected = (roi: [number, number, number, number] | null) => {
    setRoi(roi);
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Box display="flex" gap={2}>
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="capture-button-file"
              type="file"
              capture="environment"
              onChange={handleImageChange}
            />
            <label htmlFor="capture-button-file">
              <Button variant="contained" color="success" component="span">
                <CameraAltIcon />
                {isLargeScreen && (
                  <Typography variant="caption" ml={1}>
                    Capture
                  </Typography>
                )}
              </Button>
            </label>
          </Box>
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="select-button-file"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="select-button-file">
              <Button variant="contained" color="success" component="span">
                <CloudUploadIcon />
                {isLargeScreen && (
                  <Typography variant="caption" ml={1}>
                    Upload
                  </Typography>
                )}
              </Button>
            </label>
          </Box>
        </Box>

        <Button
          variant="contained"
          disabled={roi === null || imageFile === null}
          onClick={handleDetect}
        >
          <SearchIcon />
          {isLargeScreen && (
            <Typography variant="caption" ml={1}>
              Detect
            </Typography>
          )}
        </Button>
      </Box>

      <RoiSelection
        src={imageFile ? selectedImage : "https://via.placeholder.com/800x400"}
        onRoiSelected={handleRoiSelected}
      />
    </Container>
  );
};

export default Home;
