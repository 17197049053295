import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import Home from "./components/Home";
import ResultDetails from "./components/ResultDetails";
import Results from "./components/Results";

function App() {
  return (
    <div className="App">
      <Router>
        <AppBar component="nav">
          <Toolbar>
            <Typography variant="h6" sx={{ my: 2 }}>
              Planogram
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/results" element={<Results />} />
            <Route path="/resultdetails" element={<ResultDetails />} />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
