import React, { useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  classIds: number[] | null;
}

const ReportDialog: React.FC<Props> = ({ open, onClose, classIds }) => {
  // Count the occurrences of each classId
  const classIdCounts = useMemo(() => {
    if (!classIds) return {};
    return classIds.reduce((acc: Record<number, number>, classId: number) => {
      acc[classId] = (acc[classId] || 0) + 1;
      return acc;
    }, {});
  }, [classIds]);

  const classIdEntries = Object.entries(classIdCounts);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { height: "100%" } }}
    >
      <DialogTitle>Class ID Counts</DialogTitle>
      <DialogContent dividers>
        {classIdEntries.length === 0 ? (
          <div>No data to show</div>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Class ID</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classIdEntries.map(([classId, count]) => (
                  <TableRow key={classId}>
                    <TableCell>{classId}</TableCell>
                    <TableCell>{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
