import React, { useState, useRef, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Button,
  Typography,
  useMediaQuery,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BugReportIcon from "@mui/icons-material/BugReport";

import axios, { AxiosResponse } from "axios";

import { LinearProgressTopBar } from "./ProgressBar";
import ReportDialog from "./ReportDialog";
import { ErrorDialog } from "./ErrorDialog";

interface Project {
  project_id: number;
  project_name: string;
}

interface Class {
  class_id: number;
  class_name: string;
  first_index: number;
}

const theme = createTheme();

const Results: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { croppedImage } = location.state || {};

  const [progress, setProgress] = useState<number>(0);
  const [rois, setRois] = useState<number[][] | null>(null);
  const [classIds, setClassIds] = useState<number[] | null>(null);
  const [confLevels, setConfLevels] = useState<number[] | null>(null);
  const [croppedImages, setCroppedImages] = useState<string[] | null>(null);
  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false);
  const [debugDialogOpen, setDebugDialogOpen] = useState<boolean>(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);
  const [infError, setInfError] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  async function urlToFile(
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }

  const cropImage = async (roi: number[]): Promise<Blob | null> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = croppedImage;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) {
          reject(new Error("Failed to get canvas context"));
          return;
        }

        const [topLeftX, topLeftY, bottomRightX, bottomRightY] = roi;
        const width = bottomRightX - topLeftX;
        const height = bottomRightY - topLeftY;

        // Set canvas dimensions to match the crop dimensions
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(
          image,
          topLeftX,
          topLeftY,
          width,
          height, // Source
          0,
          0,
          width,
          height // Destination
        );

        // Convert the canvas to a blob
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to convert canvas to blob"));
            }
          },
          "image/jpeg" // You can choose the format (e.g., 'image/png')
        );
      };

      image.onerror = () => {
        reject(new Error("Failed to load image"));
      };
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const img = new Image();
    img.src = croppedImage;

    console.log("ROIs:", rois);
    console.log("IDs:", classIds);

    img.onload = () => {
      if (canvas && ctx) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight * 0.75;

        const imgAspectRatio = img.width / img.height;
        const windowAspectRatio = windowWidth / windowHeight;

        let canvasWidth: number;
        let canvasHeight: number;

        if (windowAspectRatio > imgAspectRatio) {
          // Window is wider relative to its height than the image
          canvasHeight = windowHeight;
          canvasWidth = windowHeight * imgAspectRatio;
        } else {
          // Window is taller relative to its width than the image
          canvasWidth = windowWidth;
          canvasHeight = windowWidth / imgAspectRatio;
        }

        // Set the canvas size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        if (isLargeScreen) {
          ctx.font = "30px Arial"; // Font size and type
        } else {
          ctx.font = "15px Arial"; // Font size and type
        }
        ctx.textAlign = "left"; // Center-align text horizontally
        ctx.textBaseline = "top"; // Center-align text vertically

        if (rois) {
          const scaleX = canvas.width / img.width;
          const scaleY = canvas.height / img.height;
          rois.forEach((roi, index) => {
            const [topLeftX, topLeftY, bottomRightX, bottomRightY] = roi;
            const width = bottomRightX - topLeftX;
            const height = bottomRightY - topLeftY;

            ctx.strokeRect(
              topLeftX * scaleX,
              topLeftY * scaleY,
              width * scaleX,
              height * scaleY
            );

            if (classIds) {
              // Assuming ctx is the 2D drawing context of a canvas and other variables are defined
              const id_string = classIds[index].toString();
              const text = `${id_string}`; // Corrected template literal usage

              // Measure text dimensions
              const textMetrics = ctx.measureText(text);
              const textWidth = textMetrics.width;
              const textHeight = 30; // Approximate height, adjust as needed

              // Draw filled rectangle
              ctx.fillStyle = "red";
              ctx.fillRect(
                topLeftX * scaleX,
                topLeftY * scaleY,
                textWidth,
                textHeight
              );

              // Draw text on top of rectangle
              ctx.fillStyle = "white";
              ctx.textBaseline = "top"; // Ensure text starts from the top-left corner
              ctx.fillText(text, topLeftX * scaleX, topLeftY * scaleY);
            }
          });
        }
      }
    };
  }, [croppedImage, rois, classIds]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resAllProjects = await axios.get<{ data: Project[] }>(
          "/sol_server/project/all_projects"
        );
        const projects = resAllProjects.data.data;
        setProgress(10);

        const infProject = projects.find(
          (project) => project.project_name === "Joseph_obj"
        );
        const projectId = infProject?.project_id;
        let projectURL = `/sol_server/project/${projectId}`;
        const resProject = await axios.get(projectURL);
        const projectDetails = resProject.data.data[0];
        // console.log(projectDetails);
        setProgress(20);

        const regData = {
          username: "guest",
          project_id: projectId,
          state_id: projectDetails.state_id,
        };

        const resRegister = await axios.post(
          "/sol_server/job/trigger",
          regData
        );

        const regDetails = resRegister.data.data;
        // console.log(regDetails);
        setProgress(30);

        const infHeader = {
          "Content-Type": "multipart/form-data",
          "Tool-Name": projectDetails.tool,
          "Model-Id": projectDetails.model.model_id,
          "Job-Id": regDetails.job_id,
          "State-Id": projectDetails.state_id,
          threshold: 0.7,
          split_text: 0,
        };

        const infImageData = new FormData();
        const file = await urlToFile(croppedImage, "image.jpg", "image/jpeg");
        infImageData.append("files", file);

        const infResponse = await axios.post(
          "/sol_server/inference",
          infImageData,
          {
            headers: infHeader,
          }
        );

        const infData = infResponse.data.data;
        // console.log(infData);
        setProgress(50);

        // setClassIds(infData.class_ids);
        if (infData.rois.length == 0) {
          setInfError(true);
          setProgress(100);
          setErrorDialogOpen(true);
          return;
        }

        setRois(infData.rois);

        const clsProject = projects.find(
          (project) => project.project_name === "joseph_classification"
        );
        const clsProjectId = clsProject?.project_id;
        let clsProjectURL = `/sol_server/project/${clsProjectId}`;
        const resClsProject = await axios.get(clsProjectURL);
        const clsProjectDetails = resClsProject.data.data[0];
        // console.log(clsProjectDetails);
        setProgress(60);

        const regClsData = {
          username: "guest",
          project_id: clsProjectId,
          state_id: clsProjectDetails.state_id,
        };

        const resClsRegister = await axios.post(
          "/sol_server/job/trigger",
          regClsData
        );

        const regClsDetails = resClsRegister.data.data;
        // console.log(regClsDetails);
        setProgress(70);

        let ClassIds: number[] = Array(infData.rois.length).fill(-1);
        let ConfLevels: number[] = Array(infData.rois.length).fill(-1);
        let CroppedImages: string[] = Array(infData.rois.length).fill("");
        const progressInc = 30 / infData.rois.length;

        try {
          for (const [index, roi] of infData.rois.entries()) {
            const imageFile = await cropImage(roi);

            if (imageFile) {
              // console.log(URL.createObjectURL(imageFile));
              CroppedImages[index] = URL.createObjectURL(imageFile);
              const clsHeader = {
                "Content-Type": "multipart/form-data",
                "Tool-Name": clsProjectDetails.tool,
                "Model-Id": clsProjectDetails.model.model_id,
                "Job-Id": regClsDetails.job_id,
                "State-Id": clsProjectDetails.state_id,
                threshold: 0.7,
                split_text: 0,
              };

              const clsImageData = new FormData();
              clsImageData.append("files", imageFile);

              const clsResponse = await axios.post(
                "/sol_server/inference",
                clsImageData,
                {
                  headers: clsHeader,
                }
              );

              const clsData = clsResponse.data.data;
              // console.log(clsData);
              if (clsData.class_ids.length > 0) {
                ClassIds[index] = clsData.class_ids[0];
                ConfLevels[index] = clsData.scores[0];
              }
              setProgress(70 + progressInc * (index + 1));
            }
          }
          setClassIds(ClassIds);
          setConfLevels(ConfLevels);
          setCroppedImages(CroppedImages);
        } catch (error) {
          console.error("Error processing images:", error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 2 }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          <ArrowBackIosIcon />
          {isLargeScreen && (
            <Typography variant="caption" ml={1}>
              Menu
            </Typography>
          )}
        </Button>
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="success"
            onClick={() => setReportDialogOpen(true)}
          >
            <FormatListBulletedIcon />
            {isLargeScreen && (
              <Typography variant="caption" ml={1}>
                Report
              </Typography>
            )}
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => setDebugDialogOpen(true)}
          >
            <BugReportIcon />
            {isLargeScreen && (
              <Typography variant="caption" ml={1}>
                Debug
              </Typography>
            )}
          </Button>
        </Box>
      </Box>

      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <canvas
            ref={canvasRef}
            style={{
              border: "1px solid #ddd",
              display: "block",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <LinearProgressTopBar
        value={progress}
        color={infError ? "error" : "primary"}
      />
      <Dialog
        open={debugDialogOpen}
        onClose={() => setDebugDialogOpen(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { height: "100%" } }}
      >
        <DialogTitle>Image Details</DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Class ID</TableCell>
                  <TableCell>Confidence Level</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classIds?.length === 0 ||
                confLevels?.length === 0 ||
                croppedImages?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No data to show
                    </TableCell>
                  </TableRow>
                ) : (
                  croppedImages?.map((image, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <img
                          src={image}
                          alt={`Cropped ${index}`}
                          style={{ maxHeight: "100px", maxWidth: "100px" }}
                        />
                      </TableCell>
                      <TableCell>{classIds?.[index] ?? "N/A"}</TableCell>
                      <TableCell>{confLevels?.[index] ?? 0}%</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDebugDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ReportDialog
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        classIds={classIds}
      />
      <ErrorDialog
        open={errorDialogOpen}
        onClose={() => setErrorDialogOpen(false)}
      />
    </Container>
  );
};

export default Results;
