import React, {
  useState,
  useRef,
  useEffect,
  MouseEvent,
  TouchEvent,
} from "react";
import { Box, Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CreateIcon from "@mui/icons-material/Create";

interface Point {
  x: number;
  y: number;
}

interface RoiSelectionProps {
  src: string;
  onRoiSelected: (roi: [number, number, number, number] | null) => void;
}

const RoiSelection: React.FC<RoiSelectionProps> = ({ src, onRoiSelected }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [roi, setRoi] = useState<[Point, Point, Point, Point] | null>(null);
  const [draggingPoint, setDraggingPoint] = useState<number | null>(null);
  const [imageSize, setImageSize] = useState<[number, number] | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    const img = new Image();
    img.src = src;

    img.onload = () => {
      if (canvas && ctx) {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight * 0.75;

        const imgAspectRatio = img.width / img.height;
        const windowAspectRatio = windowWidth / windowHeight;

        let canvasWidth: number;
        let canvasHeight: number;

        if (windowAspectRatio > imgAspectRatio) {
          // Window is wider relative to its height than the image
          canvasHeight = windowHeight;
          canvasWidth = windowHeight * imgAspectRatio;
        } else {
          // Window is taller relative to its width than the image
          canvasWidth = windowWidth;
          canvasHeight = windowWidth / imgAspectRatio;
        }

        // Set the canvas size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        setImageSize([img.width, img.height]);
      }
    };
  }, [src]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const preventDefault = (e: Event) => {
        e.preventDefault();
      };

      canvas.addEventListener("touchstart", preventDefault, { passive: false });
      canvas.addEventListener("touchmove", preventDefault, { passive: false });
      canvas.addEventListener("touchend", preventDefault, { passive: false });

      return () => {
        canvas.removeEventListener("touchstart", preventDefault);
        canvas.removeEventListener("touchmove", preventDefault);
        canvas.removeEventListener("touchend", preventDefault);
      };
    }
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (canvas && ctx && roi) {
      // ctx.clearRect(0, 0, canvas.width, canvas.height);
      const image = new Image();
      image.src = src;

      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        const [topLeft, topRight, bottomLeft, bottomRight] = roi;
        ctx.strokeStyle = "red";
        ctx.lineWidth = 2;
        ctx.strokeRect(
          topLeft.x,
          topLeft.y,
          topRight.x - topLeft.x,
          bottomLeft.y - topLeft.y
        );

        const drawHandle = (point: Point) => {
          ctx.beginPath();
          ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
          ctx.fillStyle = "white";
          ctx.fill();
          ctx.strokeStyle = "red";
          ctx.stroke();
        };

        drawHandle(topLeft);
        drawHandle(topRight);
        drawHandle(bottomLeft);
        drawHandle(bottomRight);
      };
    }
  }, [roi]);

  const getRelativePosition = (x: number, y: number) => {
    const canvas = canvasRef.current;
    const rect = canvas?.getBoundingClientRect();
    if (rect && canvas) {
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      return {
        x: (x - rect.left) * scaleX,
        y: (y - rect.top) * scaleY,
      };
    }
    return { x: 0, y: 0 };
  };

  const updateRoi = (
    start: Point,
    end: Point
  ): [Point, Point, Point, Point] => {
    return [
      { x: Math.min(start.x, end.x), y: Math.min(start.y, end.y) },
      { x: Math.max(start.x, end.x), y: Math.min(start.y, end.y) },
      { x: Math.min(start.x, end.x), y: Math.max(start.y, end.y) },
      { x: Math.max(start.x, end.x), y: Math.max(start.y, end.y) },
    ];
  };

  const handleMouseDown = (e: MouseEvent<HTMLCanvasElement>) => {
    if (isSelecting) {
      setIsEditing(true);
      const position = getRelativePosition(e.clientX, e.clientY);
      if (roi) {
        const [topLeft, topRight, bottomLeft, bottomRight] = roi;
        const points = [topLeft, topRight, bottomLeft, bottomRight];
        const pointIndex = points.findIndex(
          (point) =>
            Math.abs(point.x - position.x) < 10 &&
            Math.abs(point.y - position.y) < 10
        );
        if (pointIndex !== -1) {
          setDraggingPoint(pointIndex);
          return;
        }
      }
      setRoi([position, position, position, position]);
    }
  };

  const handleMouseMove = (e: MouseEvent<HTMLCanvasElement>) => {
    if (isSelecting && roi && isEditing) {
      const position = getRelativePosition(e.clientX, e.clientY);
      if (draggingPoint !== null) {
        let newRoi = [...roi];
        switch (draggingPoint) {
          case 0:
            newRoi = updateRoi(position, roi[3]);
            break;
          case 1:
            newRoi = updateRoi(
              { x: roi[2].x, y: position.y },
              { x: position.x, y: roi[2].y }
            );
            break;
          case 2:
            newRoi = updateRoi(
              { x: position.x, y: roi[1].y },
              { x: roi[1].x, y: position.y }
            );
            break;
          case 3:
            newRoi = updateRoi(roi[0], position);
            break;
        }
        setRoi(newRoi as [Point, Point, Point, Point]);
      } else {
        setRoi(updateRoi(roi[0], position));
      }
    }
  };

  const handleMouseUp = () => {
    if (isSelecting) {
      setDraggingPoint(null);
      setIsEditing(false);

      if (roi) {
        const [topLeft, , , bottomRight] = roi;
        const canvas = canvasRef.current;

        if (canvas && imageSize) {
          const rect = canvas.getBoundingClientRect();
          const scaleX = imageSize[0] / canvas.width;
          const scaleY = imageSize[1] / canvas.height;

          // Calculate the top-left X, top-left Y, width, and height with respect to the original image dimensions
          const originalTopLeftX = topLeft.x * scaleX;
          const originalTopLeftY = topLeft.y * scaleY;
          const width = (bottomRight.x - topLeft.x) * scaleX;
          const height = (bottomRight.y - topLeft.y) * scaleY;

          const originalRoi = [
            originalTopLeftX,
            originalTopLeftY,
            width,
            height,
          ] as [number, number, number, number];

          onRoiSelected(originalRoi);
        }
      }
    }
  };

  const handleTouchStart = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseDown(e.touches[0] as any);
  };

  const handleTouchMove = (e: TouchEvent<HTMLCanvasElement>) => {
    handleMouseMove(e.touches[0] as any);
  };

  const handleTouchEnd = () => {
    handleMouseUp();
  };

  const handleToggleROI = () => {
    setIsSelecting(!isSelecting);
  };

  const handleClearROI = () => {
    setRoi(null);
    onRoiSelected(null);
    setDraggingPoint(null);

    // Clear the canvas
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");

    if (canvas && ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Redraw the image after clearing the canvas
      const image = new Image();
      image.src = src;
      image.onload = () => {
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      };
    }
  };

  return (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      <Box sx={{ position: "relative", display: "inline-block" }}>
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          style={{ border: "1px solid #ddd", display: "block", width: "100%" }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            display: "flex",
            flexDirection: "row",
          }}
          gap={1}
        >
          <Button
            variant="contained"
            color={isSelecting ? "secondary" : "primary"}
            onClick={handleToggleROI}
          >
            <CreateIcon />
          </Button>
          <Button variant="contained" color="error" onClick={handleClearROI}>
            <ClearIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RoiSelection;
